<template>
    <div>

        <modal-confirm @confirm="setSchedule" inputId="confirmModal" title="Potvrda upućivanja na radni nalog" message="Da li ste sigurni da želite da uputite radnika na odabrani radni nalog?"/>
        <modal-p-w-o-confirm @confirm="setSchedule" inputId="confirmPWOModal" :employee="selectedEmployee" :selectedDate="getDateTimeSeconds()" :assignData.sync="scheduleData" />


        <div class="table-title">
            <div class="container-xl">
                <div class="row">
                    <div class="btn-group col-md-4" role="group" aria-label="Basic outlined example">
                        <template v-if="user.role == 'ADMIN'">
                            <router-link :to="{ name: 'EmployeesAssignment'}" class="btn btn-primary active">Na radni nalog</router-link>
                            <router-link :to="{ name: 'EmployeesAssignmentTrip', params: {'initalSelectedDate': selected_date }}" class="btn btn-primary">Dodeli vozila</router-link>
                            <router-link :to="{ name: 'EmployeesAssignmentAndTrip', params: {'initalSelectedDate': selected_date }}" class="btn btn-primary">Dnevni prikaz</router-link>
                        </template>
                    </div>

                    <div class="d-flex flex-wrap justify-content-end col-md-8">
                        <div>
                            <div class="form-floating">
                                <input type="date" class="form-control" id="woEndDate" v-model="selected_date" placeholder="Za datum" required @change="changedDateLoad">
                                <label for="woEndDate">Za datum</label>
                                <div class="invalid-feedback">
                                    Unesite datum
                                </div>
                            </div>
                        </div>
                        <template v-if="user.role == 'ADMIN'">
                            <div v-if="showTimeInput" class="d-flex me-3">
                                <div class="form-floating">
                                    <input type="time" class="form-control" id="woEndDate" v-model="selected_time" placeholder="Vreme" required @change="changedDateLoad">
                                    <label for="woEndDate">Vreme</label>
                                    <div class="invalid-feedback">
                                        Unesite vreme
                                    </div>
                                </div>
                                <button type="button" class="btn time-close" v-on:click="resetTime">✕</button>
                            </div>
                            
                            <div v-else class="me-3">
                                <button type="button" class="btn white-btn" v-on:click="showTime">Vreme</button>
                            </div>
                        </template>
                        <div class="d-flex">
                            <div>
                                <div class="form-floating">
                                    <input type="number" min="0" max="30" class="form-control" id="daysBefore" placeholder="broj dana pre" v-model.number="daysBefore" @change="changeSelectedDays">
                                    <label for="daysBefore">Pre</label>
                                </div>
                            </div>
                            <div>
                                <div class="form-floating">
                                    <input type="number" min="0" max="30" class="form-control" id="daysAfter" placeholder="broj dana posle" v-model.number="daysAfter" @change="changeSelectedDays">
                                    <label for="daysAfter">Posle</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-lg-12 control-section" v-if="employees">
            <div class="e-mobile-content">
                <ejs-grid
                    v-if="gridReady"
                    ref="empAssignment"
                    :dataSource="employeesWorkers" 
                    :allowFiltering='true'
                    :allowSorting='true'
                    :showColumnMenu='true'
                    :allowGrouping='true'
                    :groupSettings='groupOptions'
                    :filterSettings='filterSettings'
                    :actionComplete='actionCompleteEvent'
                    :resizeStop='resizeEvent'
                    :created="createdTable"
                    :allowSelection='false'
                    :allowResizing='true'
                    :allowReordering='true'
                    gridLines='Both'
                    :queryCellInfo='customiseCell'
                    class="employess-table"
                    :enablePersistence='false'
                    height='100%'
                    :toolbar='toolbar'
                    :toolbarClick='clickHandler'
                    :allowExcelExport='true'
                    :allowPdfExport='true'
                    :recordDoubleClick="recordDoubleClick"
                    :actionFailure='actionFailure'
                    :dataBound='dataBound'
                    :refresh='loadEvent'
                    >
                    <e-columns>
                        <e-column field=''              index="3" order='4'    headerText='#' :template="rowIndexTemplate" :width='widthStates[0]' textAlign='Right' :visible='visibleStates[0]' ></e-column>
                        <e-column field='id'                headerText='ID'         :width='widthStates[1]' textAlign='Right' :isPrimaryKey='true' :visible='visibleStates[1]' ></e-column>
                        <e-column field='user.username'     headerText='Korisničko ime' :width='widthStates[2]' textAlign='Left' :visible='visibleStates[2]' :template="user.role==='ADMIN'?usernameTemplate:undefined"></e-column>
                        <e-column field='user.last_name'    headerText='Prezime'    :width='widthStates[3]' :visible='visibleStates[3]' ></e-column>
                        <e-column field='user.first_name'   headerText='Ime'        :width='widthStates[4]' :visible='visibleStates[4]' :customAttributes="infoAttributes" ></e-column>
                        
                        <e-column field='transport_type' :valueAccessor='transportTypeAccessor' headerText='Tip prevoza' :width='widthStates[5]'        :visible='visibleStates[5]' :customAttributes="infoAttributes" ></e-column>
                        <e-column field='user.role' :valueAccessor='roleAccessor' headerText='Uloga' :width='widthStates[6]'  :visible='visibleStates[6]' :customAttributes="infoAttributes" ></e-column>

                        <e-column v-if="isWeekend" field='w2.display' headerText="Preprošla nedelja"  width='177' :visible='isWeekend' ></e-column>
                        <e-column v-if="isWeekend" field='w1.display' headerText="Prošla nedelja"  width='177' :visible='isWeekend' ></e-column>

                        <e-column v-for="time, timeId in allTimes" :key="timeId + selected_time" v :field='"timeline." + timeId + ".display"' width='177' 
                                :template="todayTimeId==timeId && user.role==='ADMIN'?pwoTemplate(timeId):undefined" :headerText='headerTextTimeAssign(time)'  
                                :visible='visibleStates[7+timeId]' :customAttributes="{class: 'time-col-' + timeId}" :valueAccessor='timelineAccessor' :disableHtmlEncode='false'></e-column>
                        
                        <!-- <e-column field='Freight' headerText='Freight' width='120' format='C2' textAlign='Right'></e-column>
                        <e-column field='ShippedDate' headerText='Shipped Date' width='130' format="yMd" textAlign='Right'></e-column>
                        <e-column field='ShipCountry' headerText='Ship Country' width='150'></e-column> -->
                    </e-columns>
                </ejs-grid>
            </div>


        </div>
                
        <div class="assignment-form-footer" v-if="editedEmployees > 0">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <h5 class="mt-2">Izmenili ste {{editedEmployees}} rasporeda</h5>
                    <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <button @click="submitAssignmentBtn" class="btn btn-primary">
                            Potvrdi
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import ModalConfirm from '@/components/modals/ModalConfirm.vue';
    import { Modal } from 'bootstrap';

    import EmployeesService from '@/service/EmployeesService.js';
    import CalendarService from '@/service/CalendarService.js';
    import UtilService from '@/service/UtilService.js';
    import {mapState} from 'vuex';
    import Vue from 'vue';

    import { Sort, Page, ColumnMenu, Filter, Group, Reorder, Resize, Freeze, Toolbar, PdfExport, ExcelExport } from "@syncfusion/ej2-vue-grids";
    import DateTimeEmpAssignment from '@/components/grid-templates/DateTimeEmpAssignment.vue';
    import PreWorkOrderTemplate from '@/components/grid-templates/PreWorkOrder.vue';
    import RowIndexTemplate from '@/components/grid-templates/RowIndex.vue';
    import ModalPWOConfirm from '../modals/ModalPWOConfirm.vue';
    import UsernameTemplate from '@/components/grid-templates/UsernameTemplate.vue';
    import WorkOrderService from '@/service/WorkOrderService.js';


    Vue.prototype.$eventHub = new Vue(); 


    export default {

        components: { ModalConfirm, ModalPWOConfirm },

        setup: {
            UtilService
        },
        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Reorder, Resize, Freeze, Toolbar, PdfExport, ExcelExport ]
        },

        data: function() {
            return {
                
                gridReady: false,
                employees: [],
                selectedEmployee: null,
                preWorkOrders: {},

                allTimes: [],
                pwoSchedulesMap: {},
                currentEmployeeWO: {},

                currentWorkOrder: {
                    workOrderId: null,
                    date: null
                },

                showTimeInput: false,
                selected_date: UtilService.formatDatePattern(UtilService.addDate(UtilService.getMoment(), 1, 'day')),
                selected_time: "00:00",
                daysBefore: 1,
                daysAfter: 1,

                modalConfirm: null,
                scheduleData: {},
                absences: null,
                pwoTimeline: null,


                groupOptions: { showGroupedColumn: true, columns: [] },
                groupOptionsLoadedColumns: [],
                filterSettings: { type: "Excel" },
                
                visibleStates: [true, false, false, true, true, true, false, true],
                widthStates: [60, 120, 120, 130, 130, 150, 150, 377, 177, 177, 177, 177, 177,],
                orderStates: [],
                groupState: [],

                infoAttributes: {class: 'col-info-side'},

                nonWorkingDays: [],
                employeesDayStatus: {},

                workAttendances: [],

                editedEmployees: 0,

                toolbar: [

                    { text: 'Exprot Excel', tooltipText: 'Izvezi Excel', prefixIcon: 'e-excelexport', id: 'exportExcel', align: 'Right'},
                    { text: 'Exprot PDF', tooltipText: 'Izvezi PDF', prefixIcon: 'e-pdfexport', id: 'exportPDF', align: 'Right'},
                    { text: 'Print', tooltipText: 'Štampaj', prefixIcon: 'e-print', id: 'print', align: 'Right'},
                    ],

                dateTimeHeaderTemplate() {
                    return {
                        template: DateTimeEmpAssignment
                    };
                },

                pwoTemplate(timeId) {
                    return () => {
                        return {
                            template: {
                                extends: PreWorkOrderTemplate,
                                propsData: {timeId: timeId, dateTimeSeconds: this.getDateTimeSeconds(), scheduleData: this.scheduleData, nonWorkingDays: this.nonWorkingDays},
                            }
                        }
                    }
                },

                rowIndexTemplate() {
                        return {
                            template: {
                                extends: RowIndexTemplate,
                            }
                        }

                },


                usernameTemplate: function() {
                    return {
                        template: UsernameTemplate
                    };
                },

            };
        },  
        computed: {
            ...mapState([
                'allWorkOrders',
                'userSettings',
                'user',
            ]),

            leftBoundDate() {
                return UtilService.convertMoment(this.selected_date).add(-this.daysBefore, 'day');
            },

            rightBoundDate() {
                return UtilService.convertMoment(this.selected_date).add(this.daysAfter, 'day');
            },

            employeesWorkers() {
                let workers = []
                this.employees.forEach(employee => {
                    // if (employee.user.role == "ADMIN") return;
                    workers.push(employee)
                });

                return workers

            },

            todayTimeId() {
                return this.allTimes.indexOf(this.getDateTimeSeconds());
            },

            isWeekend() {
                let selected_date = new Date(this.selected_date);
                if (selected_date.getDay() == 0 || selected_date.getDay() == 6) return true;
                return false;

            },

            todayDateTime() {
                return UtilService.formatDatePattern() + "T00:00:00";
            }
        },

        methods: {

            formatDate: UtilService.formatDate,
            translateRole: UtilService.translateRole,
            absenceTypeFormat: UtilService.absenceTypeFormat,
            translateTransportType: UtilService.translateTransportType,

            roleAccessor(field, data) {
                if (field == "key") return this.translateRole(data.key);

                return this.translateRole(data['user'].role);
            },

            transportTypeAccessor(field, data) {
                if (field == "key") return this.translateTransportType(data.key);

                return this.translateTransportType(data['transport_type']);
            },

            async changedDateLoad() {
                await this.loadTimelineWorkorders();

            },

            submitAssignmentBtn() {
                alert("Upućeno");
                this.loadTimelineWorkorders();
                this.editedEmployees = 0;
            },

            getCreatedByTag(data, tooltip) {
                return "<small title='Izmenjeno: " + UtilService.formatDateTime(tooltip) +  "'>" + data.first_name + " " + data.last_name + "</small>";
            },

            timelineAccessor(field, data) {
                if (field == "key") return data.key;
                let fieldTokens = field.split('.');
                if (!data.timeline) return "";

                let pwo = data.timeline[fieldTokens[1]];
                let role = pwo?.pwo_role==1 && pwo.working?"(M) ":"";
                let name = pwo?.display?pwo.display:""

                if (pwo?.absence_type) {
                    name = UtilService.translateAbsenceType(pwo.absence_type);
                    // if (pwo?.request_message) name += " (" + pwo.request_message + ")";
                }
                else if (pwo?.non_working_day && !pwo?.force_working_day) {
                    name = "Neradni dan";
                }

                else if (pwo?.work_order === null) {
                    name = "Neradni dan"
                }
                
                let wo_tag = "<span>" + role + name + "</span>"

                if (pwo?.manager_changed && pwo?.created_by_obj) {
                    wo_tag += "<br>"
                    wo_tag += this.getCreatedByTag(pwo.created_by_obj, pwo.created_at)
                }

                return wo_tag;
            },

            customiseCell(args) {
                let currentDay;
                if (args.column.field.startsWith('timeline.') == true) {
                    let trimmed = args.column.field.slice(9);
                    let nextDot = trimmed.indexOf('.');
                    trimmed = trimmed.slice(0, nextDot);

                    if (args.data?.timeline == null) return;
                    currentDay = args.data?.timeline[trimmed];
                    if (!currentDay) return;

                    if (currentDay.absence_type) {
                        args.cell.classList.add('absence-day');
                        if (currentDay.absence_type == 'VACATION') args.cell.classList.add('absence-vacation-day');
                        else if (currentDay.absence_type == 'SICKNESS') args.cell.classList.add('absence-sickness-day');
                        else if (currentDay.absence_type == 'RELIGIOUS') args.cell.classList.add('absence-religious-day');
                        else args.cell.classList.add('absence-other-day');
                        if (currentDay.span) args.colSpan = currentDay.span;
                    } else if (currentDay.non_working_day && !currentDay.force_working_day) {
                        args.cell.classList.add('non-working-day');
                    } else if (currentDay.work_order === null) {
                        args.cell.classList.add('no-work-order');
                    } else if (currentDay.working && currentDay.date <= this.todayDateTime && currentDay.appeared == undefined) {
                        args.cell.classList.add('not-appeared')
                    }


                    if (currentDay.edited) {
                        args.cell.classList.add('edited')
                    }
                    // if (currentDay.nonWorkingDay) {
                    //     args.cell.classList.add('non-working-day');
                    // }


                    
                }

                if (args.column.field.startsWith('w1.') == true || args.column.field.startsWith('w2.') == true) {
                    let pref = args.column.field.slice(0,2);
                    currentDay = args.data?.[pref];
                    if (!currentDay) return;

                    
                    if (currentDay.absence_type) {
                        args.cell.classList.add('absence-day');
                        if (currentDay.absence_type == 'VACATION') args.cell.classList.add('absence-vacation-day');
                        else if (currentDay.absence_type == 'SICKNESS') args.cell.classList.add('absence-sickness-day');
                        else if (currentDay.absence_type == 'RELIGIOUS') args.cell.classList.add('absence-religious-day');
                        else args.cell.classList.add('absence-other-day');
                    } else if (currentDay.non_working_day && !currentDay.force_working_day) {
                        args.cell.classList.add('non-working-day');
                    } else if (currentDay.work_order === null) {
                        args.cell.classList.add('no-work-order');
                    } else if (currentDay.working && currentDay.date <= this.todayDateTime && currentDay.appeared == undefined) {
                        args.cell.classList.add('not-appeared')
                    }
                    

                }

            },

            changeSelectedDays() {
                this.loadTimelineWorkorders();
                this.saveSelectedDays();
            },

            resetTime() {
                this.hideTime();
                this.loadTimelineWorkorders();
            },

            hideTime() {
                this.showTimeInput = false;
                this.selected_time = "00:00";
            },

            showTime() {
                this.showTimeInput = true;
            },

            validateForm(form) {
                form.classList.add('was-validated');
                if (this.selectedEmployee == "" || this.currentWorkOrder.workOrderId == "" || this.currentWorkOrder.date == "")
                    return false;
    
                return form.checkValidity();
            },

            async loadActiveWorkOrders() {
                await this.$store.dispatch('loadAllWorkOrders');
            },

            getDateTime() {
                return this.selected_date + 'T' + this.selected_time;
            },


            getDateTimeSeconds() {
                return this.selected_date + 'T' + this.selected_time + ':00';
            },

            async loadTimelineWorkorders() {

                this.resetTableTimelineVisibility();

                this.loadPwoTimeline()

                // this.reorderColuns();

            },



            async includeAdditionalDays() {
                if (!this.isWeekend) return

                let w1 = new Date(this.selected_date);
                w1.setDate(w1.getDate() - 7)
                let w2 = new Date(this.selected_date);
                w2.setDate(w2.getDate() - 14)

                let w1_data = {
                    'start_date': w1.toISOString().slice(0,10),
                    'end_date': w1.toISOString().slice(0,10)
                }
                let w2_data = {
                    'start_date': w2.toISOString().slice(0,10),
                    'end_date': w2.toISOString().slice(0,10)
                }



                await WorkOrderService.getPWOTimeline(w1_data).then((result)=>{
                    this.employees.forEach(emp => {
                        let data = result.data.find(x => x.id === emp.id)?.timeline;
                        if (!data) return;

                        emp.w1 = Object.values(data)[0];
                        this.calcDeisplayValueForTimelineData(emp.w1)

                    });
                });

                await WorkOrderService.getPWOTimeline(w2_data).then((result)=>{
                    this.employees.forEach(emp => {
                        let data = result.data.find(x => x.id === emp.id)?.timeline;
                        if (!data) return;

                        emp.w2 = Object.values(data)[0];
                        this.calcDeisplayValueForTimelineData(emp.w2)
                    });
                });

            },

            refreshGrid() {
                this.$refs.empAssignment?.refresh();
            },


            getUndefinedSchedule() {
                return {work_order:undefined, display:"Neradni dan"};
            },


            async load_data() {
                await this.loadActiveWorkOrders();
                await this.loadTimelineWorkorders();

            },

            dateHasTime(str) {
                return UtilService.convertMoment(str).format('HH:mm:ss') !== "00:00:00"
            },

            formatDateAndOrTime(str) {
                let date = UtilService.convertMoment(str);
                if (date.format('HH:mm:ss') === "00:00:00") {
                    return UtilService.formatDate(date)
                } else {
                    return UtilService.formatDateTime(date)
                }

            },

            setScheduleEvent(data, showModal = true) {
                console.log("SET SCHEDULE");
                this.scheduleData = data;
                this.selectedEmployee = this.employees.find(x => x.id == data.employee);
                this.gridSelectedEmplopyee = this.$refs.empAssignment.getCurrentViewRecords().find(x => x.id == data.employee);

                this.editedEmployees++;
                let timeIndex = this.allTimes.indexOf(data.date);
                this.selectedEmployee.timeline[timeIndex].edited = true;
                this.gridSelectedEmplopyee.timeline[timeIndex].edited = true;

                this.calcDeisplayValueForTimelineData(this.selectedEmployee.timeline[timeIndex]);
                this.calcDeisplayValueForTimelineData(this.gridSelectedEmplopyee.timeline[timeIndex]);

                this.refreshGrid();

                if (showModal) {
                    this.modalConfirm = new Modal(document.getElementById('confirmPWOModal'));
                    this.modalConfirm.show();
                    // this.loadTimelineWorkorders();
                } else {
                    this.setSchedule();

                }
            },

            async setSchedule() {
                let dateStr = this.scheduleData.date.slice(0,10)
                if (this.isNonWorkingDay(dateStr)){
                    let empDayStatus = {
                        employee_id: this.scheduleData.employee,
                        working: true,
                        date: dateStr,
                        deleted: false,
                    }
                    this.updateEmpDayStatus(empDayStatus)
                }


                try { 
                    await EmployeesService.addPreWorkOrderEmployee(this.scheduleData.employee, this.scheduleData); 
                } 
                catch (error) { console.log(error); }
                // this.loadTimelineWorkorders();
            },

            async setWorkingDay(data, cell_data) {
                this.$set(cell_data, 'force_working_day', true);
                this.$set(cell_data, 'edited', true);
                return await this.updateEmpDayStatus(data);

            },

            async deleteWorkingDay(data, cell_data) {
                cell_data.force_working_day = false;
                this.getDisplayValue(cell_data);
                return await this.deleteEmpDayStatus(data);
            },


            isDateToday(datetune) {
                let date = UtilService.convertMoment(datetune);
                return date.isSame(UtilService.getMoment(), 'day');
            },

            changeDate(datetime) {
                let date = UtilService.convertMoment(datetime);
                this.selected_date = date.format("YYYY-MM-DD")
                this.selected_time = date.format("HH:mm")
                if (this.selected_time != "00:00") this.showTime();
                else this.hideTime();
            },

            refreshGroups() {        
                // this.$refs.empAssignment.ej2Instances.groupModule.render()
                // this.$refs.empAssignment.ej2Instances.groupModule.renderGroupDropArea()
            },
            

            loadPwoTimeline() {
                let data = {
                    'start_date': this.leftBoundDate.format("YYYY-MM-DD"),
                    'end_date': this.rightBoundDate.format("YYYY-MM-DD")
                }

                WorkOrderService.getPWOTimeline(data).then(async (result)=>{
                    const self = this;
                    // this.gridReady = false;

                    this.calculateAllTimes(result.data);
                    this.timelineKeyValuesToIndex(result.data)
                    this.calcDeisplayValue(result.data);

                    this.calcCurrentAssign(result.data);
                    this.employees = result.data.filter(x=> {
                        if (x.work_end_date && x.work_end_date <= self.selected_date) return false;
                        return true;

                    })
                    await this.includeAdditionalDays();
                    this.refreshGrid();
                    this.loadGroupOptions();

                    this.gridReady = true;
                    this.fixGroupingLabel();

                });

            },

            loadGroupOptions() {
                if (this.groupOptionsLoadedColumns.length == 0) return
                this.groupOptions.columns = this.groupOptionsLoadedColumns;
                console.log("loaded group opt");
            },

            calcDeisplayValue(employees) {
                const self = this;
                employees.forEach(employee => {

                    if (!employee.timeline) return;
                    Object.values(employee.timeline).forEach(timeline_obj => {
                        

                        self.calcDeisplayValueForTimelineData(timeline_obj);

                    });

                });
            },

            calcDeisplayValueForTimelineData(timeline_obj) {
                timeline_obj.working = this.isTimelineObjWorking(timeline_obj);
                timeline_obj.display = this.getDisplayValue(timeline_obj);
            },



            getDisplayValue(timeline_obj) {
                if (timeline_obj.absence_type) return "Odsustva"
                if (timeline_obj.non_working_day && !timeline_obj.force_working_day) return "Odsustva"
                if (timeline_obj.work_order_obj) return timeline_obj.work_order_obj.name;
                return "Odsustva";
            },

            isTimelineObjWorking(timeline_obj) {
                if (timeline_obj.absence_type) return false
                if (timeline_obj.non_working_day && !timeline_obj.force_working_day) return false
                if (timeline_obj.work_order > 0) return true
                return false;
            },

            calcCurrentAssign(employees) {
                const self = this;
                console.log("CURRENT ASSSIGN");
                let selectedTimeId = self.todayTimeId.toString();

                employees.forEach(employee => {

                    
                    employee.currentAssign = employee.timeline[selectedTimeId];
                    if (employee.currentAssign == null) {
                        let undefinedSchedule = self.getUndefinedSchedule();
                        employee.timeline[this.todayTimeId] = undefinedSchedule;
                        employee.currentAssign = undefinedSchedule;
                    }

                    employee.timeline[selectedTimeId]
                });
            },

            timelineKeyValuesToIndex(employees) {
                employees.forEach(employee => {
                    let timeline = employee.timeline;
                    let newTimeline = {}
                    if (timeline != undefined) {
                        Object.values(timeline).forEach((timeline_data, index) => {
                            newTimeline[index] = timeline_data;
                        });
                    }
                    
                    employee.timeline = newTimeline;

                });
            },

            calculateAllTimes(timeline) {
                let first_timeline = timeline[0]['timeline'];

                // this.allTimes = [];
                this.$nextTick(() => {
                    this.allTimes = []
                    this.$nextTick(() => {
                        this.allTimes = Object.keys(first_timeline);
                        let currentDate = this.getDateTime()
                        if (!this.allTimes.includes(currentDate)) this.allTimes.push(currentDate)
                    })
                })
                this.$set(this, "allTimes", Object.keys(first_timeline))
                let currentDate = this.getDateTime()
                if (!this.allTimes.includes(currentDate)) this.allTimes.push(currentDate)
                // this.allTimes = Object.keys(first_timeline)
            },
            
            async updateEmpDayStatus(data) {

                try {
                    let result = await CalendarService.updateEmployeeDayStatus(data);
                    return this.nonWorkingDays[data.employee] = result.data;

                } catch (error) {
                    console.log(error);
                    alert("greska prilikom menjanja statusa radnog dana za radnika");
                    return false;
                }
            },

            async deleteEmpDayStatus(data) {

                try {
                    return await CalendarService.deleteEmployeeDayStatus(data);

                } catch (error) {
                    console.log(error);
                    alert("greska prilikom menjanja statusa radnog dana za radnika");
                    return false;
                }
            },

            findEmployeeAbsence(employeeId, date){
                let absence = this.absences.find(x => 
                    x.employee_id == employeeId &&
                    (x.end_date == null || UtilService.convertMoment(x.end_date).isSameOrAfter(date, 'day')) &&
                    UtilService.convertMoment(x.start_date).isSameOrBefore(date, 'day')
                    );
                return absence;
            },

            // async updatedHeaderEvent(event) {
            //     this.visibleStates[event.cell.colIndex] = event.cell.visible;
            // },

            resizeEvent(event) {
                this.saveColumnStatus(event.column)
                this.saveColumnStates();
            },

            actionCompleteEvent(event) {
                if (event.requestType == "columnstate") {
                    this.saveAllColumnsStatus(event.columns)
                } else if (event.requestType == "reorder") {
                    this.saveAllColumnsStatusOrderStatus()
                } else if (event.requestType == "grouping") {
                    this.groupingState(event.columnName);
                } else if (event.requestType == "ungrouping") {
                    this.unGroupingState(event.columnName);
                } else if (event.requestType == "refresh") {
                    this.refreshGroups();
                    this.fixGroupingLabel();
                }
                // console.log(event)
            },
            
            saveColumnStatus(column) {
                this.visibleStates[column.index] = column.visible;
                this.widthStates[column.index] = column.width;
            },

            resetTableTimelineVisibility() {
                let columns = this.$refs.empAssignment?.getColumns()
                if (!columns) return

                columns.forEach(column => {
                    if (!column.field.startsWith("timeline")) return

                    column.visible = true;
                    this.visibleStates[column.index] = column.visible;

                });
            },

            fixGroupingLabel() {
                let groupTags = document.querySelector('.employess-table .e-groupdroparea').children;
                groupTags.forEach(element => {
                    let text = element.firstChild.firstChild.innerText;

                    let reg1 = text.match('\\d{4}-[01]\\d-[0-3]\\d');
                    let reg2 = text.match('\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d');
                    if (reg2 != null) {
                        let rexp = new RegExp(reg2);
                        let formated = UtilService.formatDateTime(reg2[0])
                        element.firstChild.firstChild.innerText = text.replace(rexp, formated);
                    } else if (reg1 != null){
                        let rexp = new RegExp(reg1);
                        let formated = UtilService.formatDateTime(reg1[0])
                        element.firstChild.firstChild.innerText = text.replace(rexp, formated);
                        
                    }
                    
                });

                let groupsText = document.querySelectorAll('.employess-table .e-table .e-groupcaption');
                groupsText.forEach(element => {
                    let text = element.innerText;

                    let index = text.indexOf(":");
                    element.innerText = text.slice(index + 1);

                });


            },

            onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            },

            groupingState(column) {
                this.groupOptions.columns.push(column)

                this.groupOptions.columns = this.groupOptions.columns.filter(this.onlyUnique);

                this.saveColumnStates();
                this.fixGroupingLabel(column);


            },

            unGroupingState(column) {
                let index = this.groupOptions.columns.indexOf(column);
                if (index !== -1) {
                    this.groupOptions.columns.splice(index, 1);
                    this.saveColumnStates();

                }
            },

            saveAllColumnsStatus(columns) {
                columns.forEach(column => {
                    this.saveColumnStatus(column);
                });
                this.saveColumnStates();
            },

            saveAllColumnsStatusOrderStatus() {
                let columns = this.$refs.empAssignment?.getColumns()
                this.orderStates = []
                columns.forEach(element => {
                    if (element.field.startsWith('timeline')) return;

                    this.orderStates.push(element.field);

                });
                this.saveColumnStates();

            },

            saveAllColumnsStatusGrooupStatus() {
                this.saveColumnStates();
            },

            createdTable() {
                let columns = this.$refs.empAssignment.getColumns()
                this.saveAllColumnsStatus(columns);
                this.resetTableTimelineVisibility();
            },

            test() {
                alert("aloooo");
            },

            isNonWorkingDay(dateValue) {
                if (!dateValue) return false;

                let dateStr = dateValue.slice(0,10)
                return this.nonWorkingDays.some(d => d.date === dateStr );
            },

            isEmpWorkingDay(empId, dateValue) {
                let empStatuses = this.employeesDayStatus[empId];
                if (!empStatuses) return false;

                let dateStr = dateValue.slice(0,10);
                let dayStatus = empStatuses.find(d => d.date === dateStr);

                if (dayStatus?.working === true) return true;
                return false;
            },

            clickHandler(args) {

                switch (args.item.id) {
                    case 'exportExcel':
                        this.$refs.empAssignment.excelExport();
                        break;
                    case 'exportPDF':
                        this.$refs.empAssignment.pdfExport();
                        break;
                    case 'print':
                        this.$refs.empAssignment.print();
                        break;
                
                    default:
                        break;
                }

            },

            headerTextTimeAssign(timeValue) {
                return this.formatDateAndOrTime(timeValue);
            },

            recordDoubleClick(event) {
                if (event.column.field.startsWith('timeline.') == true) {

                    let timeIndex = event.column.field.split(".")[1];
                    let timeValue = this.allTimes[timeIndex];

                    this.changeDate(timeValue)
                    this.loadTimelineWorkorders();
                }

            },

            actionFailure(args) { 
                console.log(args.error);
                                if (args.error.message.includes("reading 'visible'")) {
                    let columns = this.$refs.empAssignment.getColumns().map(x => x.field);

                    let beforeLen = this.groupOptions.columns.length;
                    this.groupOptions.columns = this.groupOptions.columns.filter(x => {
                        if (columns.indexOf(x) !== -1) return x;
                    })

                    if (beforeLen == 0) return
                    if (beforeLen == this.groupOptions.columns) {
                        this.groupOptions.columns.pop()
                    }
                    this.$refs.empAssignment.groupSettings = this.groupOptions

                    this.saveColumnStates();
                    this.gridReady = false;
                    this.$nextTick(() => {
                        this.gridReady = true;
                    })

                }
            },
            
            loadLocalStorage() {
                if (this.userSettings?.empAssignment?.selectedDays?.daysBefore !== undefined) this.daysBefore = parseInt(this.userSettings.empAssignment.selectedDays.daysBefore);
                if (this.userSettings?.empAssignment?.selectedDays?.daysAfter !== undefined) this.daysAfter = parseInt(this.userSettings.empAssignment.selectedDays.daysAfter);
                if (this.userSettings?.empAssignment?.grid?.width !== undefined) this.widthStates = this.userSettings.empAssignment.grid.width;
                if (this.userSettings?.empAssignment?.grid?.visible !== undefined) this.visibleStates = this.userSettings.empAssignment.grid.visible;
                if (this.userSettings?.empAssignment?.grid?.order !== undefined) this.orderStates = this.userSettings.empAssignment.grid.order;
                if (this.userSettings?.empAssignment?.grid?.group !== undefined) this.groupOptions.columns = this.userSettings.empAssignment.grid.group;
                
            },

            saveSelectedDays() {
                let path = "empAssignment.selectedDays"
                let save_data = {
                    daysBefore: this.daysBefore,   
                    daysAfter: this.daysAfter,   
                }
                this.$store.dispatch('updateUserSettings', { path, save_data });

            },

            saveColumnStates() {

                let path = "empAssignment.grid"
                let save_data = {
                    width: this.widthStates,   
                    visible: this.visibleStates,   
                    order: this.orderStates,
                    group: this.groupOptions.columns
                }
                this.$store.dispatch('updateUserSettings', { path, save_data });
            },

            dataBound() {
                this.autoFitTimelineColumns();
                this.fixGroupingLabel();
            },

            autoFitTimelineColumns() {
                let columns = this.$refs.empAssignment.getColumns();

                columns.forEach(column => {
                    if (column.field.startsWith('timeline'))
                    this.$refs.empAssignment.autoFitColumns([column.field]);
                });

            },

            async reorderColuns() {
                await this.$refs.empAssignment.reorderColumns(this.orderStates, "");
            },

            loadEvent() {
                console.log("LOAD EVENT");
            },

            loadProp() {
                let initDate = this.$route.params.initalSelectedDate;
                if (initDate) this.selected_date = initDate;
            }

        },

        created() {
            this.loadProp();
            this.loadLocalStorage();
            this.load_data();
            // this.$eventHub.$on('setScheduleData', (data)=>{ this.setScheduleEvent(data) });
            // this.$eventHub.$on('deleteWorkingDay', (data)=>{ this.deleteWorkingDay(data) });

        },

        beforeDestroy () {
            // this.$eventHub.$off('setScheduleData');
            // this.$eventHub.$off('deleteWorkingDay');
        },
    }
    
</script>

<style>
    .employess-table table .no-work-order {
        background: #777777;
        /* color: #777777 !important; */

    }
    .employess-table table tr:hover .no-work-order {
        background: #666666 !important;
        /* color: #666666 !important; */

    }
    .employess-table table .absence-vacation-day {
        background: #0dff00;
    }
    .employess-table table tr:hover .absence-vacation-day {
        background: #0cdd01 !important;
    }


    .employess-table table .absence-sickness-day {
        background: #c46453;
    }
    .employess-table table tr:hover .absence-sickness-day {
        background: #bf5644 !important;
    }

    .employess-table table .absence-religious-day {
        background: #c46453;
    }
    .employess-table table tr:hover .absence-religious-day {
        background: #bf5644 !important;
    }

    .employess-table table .absence-other-day {
        background: #ffb649;
    }
    .employess-table table tr:hover .absence-other-day {
        background: #e9a53e !important;
    }

    .employess-table table .non-working-day {
        background: #777777;
        /* color: #777777 !important; */
    }
    .employess-table table tr:hover .non-working-day {
        background: #666666 !important;
        /* color: #666666 !important; */
    }


    .employess-table table .edited {
        background: #FFFF00;
        font-weight: bold;
    }


    .employess-table table .not-appeared {
        background: #ff775f;
    }
    .employess-table table tr:hover .not-appeared {
        background: #ff5436 !important;
    }


    .employess-table table tr td.time-col-0 {
        border-left: 3px solid #e0e0e0 !important;
    }

    .employess-table.e-grid .e-gridheader { 
        position: -webkit-sticky; 
        position: sticky; 
        top: 0px; /* The height of top nav menu. */ 
        /* border-bottom: 1px solid #222; */
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.142);
        z-index: 1; 
    }

    .employess-table .e-groupdroparea .e-grouptext {
        width: 120px;
    }

    .employess-table .e-gridcontent > .e-content {
        height: calc(100vh - 250px) !important;
    }

    .employess-table .e-table .e-groupcaption {        
        font-size: 14px;
        font-weight: bold;
    }

    .employess-table .e-table tr.e-row {        
        height: 55px;
    }

</style>

<style scoped>

.container {
    min-height: 74vh;
}

.table-title {
    padding-bottom: 15px;
    background: #299be4;
    padding: 16px 30px;
    margin: 0 0 10px;
    border-radius: 3px 3px 0 0;
}

.table-title h2{
    margin: 5px 0 0;
    font-size: 1.8em;
}

.table-title .white-btn {
    height: 100%;    
    background: #fff;
    width: 100%;
}

.table-title .btn.btn-w:hover, .table-title .btn.btn-w:focus {
    color: #566787;
    background: #f2f2f2;
}

.table-title .btn .btn-title {
    margin-top: 20px;
}

.table-wrapper {
    min-width: 1000px;
    background: #fff;
    padding: 20px 25px;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}


.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
}
table.table thead {
    font-size: 0.8em
}

.h4 {
    font-style: bold;
}

.card-img-top {
    width: 100%;
    height: 4vw;
    min-height: 150px;
    object-fit: cover;
}

#cards .card {
    min-height: 100px;
}

#cards .card-body {
    background: #ffffffa7;
    position: absolute;
    bottom: 0;
    width: 100%;
}

#cards .card:hover .card-body {
    background: #ffffffef;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.time-close {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    background: #fff;
}

.table th.absence-day {

    background: orange;
}

.table th.no-work-order {
    background: gray;
}

.assignment-form-footer {
    width: 100%;
    height: 70px;
    padding-top: 10px;
    background: rgba(255, 255, 255, 0.83);
    position: sticky;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    box-shadow: rgba(0, 0, 0, 0.14) 0 -5px 5px;
}

.assignment-form-footer .container {
    min-height: 60px !important;
}

</style>