<template>
<div>
    <Navbar />
    <Breadcrumbs></Breadcrumbs>
    <EmployeesAssignment/>
    <Footer />
</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import EmployeesAssignment from '@/components/employees/EmployeesAssignment.vue'
import Footer from '@/components/Footer.vue';

export default {
    components: {
        Navbar,
        EmployeesAssignment,
        Footer
    }
}
</script>

<style scoped>

</style>