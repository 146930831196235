<template>
    <span>{{ rowNumber }}</span>
        
</template>

<script>
export default {
    name: 'grid-row-index',

    data: function() {
        return {
            data: {},
        }
    },
    computed: {
        rowNumber() {
            let pageOffset = 0;
            if (this.data.column.parent.allowPaging) {
                const currentPage = this.data.column.parent.pageSettings.properties.currentPage;
                const pageSize = this.data.column.parent.pageSettings.properties.pageSize;
                pageOffset = (currentPage-1) * pageSize;
            }
            let index = parseInt(this.data.index)
            return index + 1 + pageOffset;
        }
    }
}
</script>
