<template>
    <div>
        <p v-if="warnings.application_end_date != undefined">
            <strong>Datum isteka prijave: </strong><span>{{getExpiredText(warnings.application_end_date)}}</span>
        </p>
        <p v-if="warnings.work_end_date != undefined">
            <strong>Datum kraja rada: </strong><span>{{getExpiredText(warnings.work_end_date)}}</span>
        </p>
        <p v-if="warnings.medical_examination_date != undefined">
            <strong>Datum lekarskog pregleda: </strong><span>{{getExpiredText(warnings.medical_examination_date)}}</span>
        </p>
        <p v-if="warnings.health_card_expiration_date != undefined">
            <strong>Datum isteka zdravstvene knjižice: </strong><span>{{getExpiredText(warnings.health_card_expiration_date)}}</span>
        </p>

    </div>
</template>

<script>


export default {
    name: 'employee-warning-template',

    
    props: {
        warnings: Object
    },

    data: function() {
        return {

        }
    },

    methods: {
        
        getExpiredText(days) {
            if (days >= 0)
                return "Ističe za " + days + " dana";
            else
                return "Istekao pre " + Math.abs(days) + " dana";
        },
    }


}
</script>
