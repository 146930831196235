<template>
    <span v-if="dataComputed" class="usernameTemplate">
        <ejs-tooltip id='tooltip' :content='content(warnings)' position='RightCenter' target='#target'>
            <a id="target">
                
                <i v-if="hasDangerWarnings()" class="fas fa-exclamation-triangle text-danger"> </i>
                <i v-else-if="hasWarnings()" class="fas fa-exclamation-triangle text-warning"> </i>
            </a>

            <router-link :to="{ name: profilePage, params:{'username': dataComputed.username, 'userId': dataComputed.id}}" :class="{'role-manager': userRole===1}">
                <img v-if="showAvatar" :src="getAvatarUrl(dataComputed)" class="rounded-circle template-avatar" width="290"/>
                <!-- <span v-if="role && userRole === 1">(M) </span> -->

                <span v-if="showLastName">{{dataComputed.last_name}} </span>
                <span v-if="showName">{{dataComputed.first_name}} </span>
                <span v-if="showAltUsername">({{dataComputed.username}}) </span>
                <span v-if="showUsername">{{dataComputed.username}} </span>
            </router-link>
        </ejs-tooltip>

    </span>
</template>

<script>

    import EmployeeWarningTooltip from '@/components/grid-templates/EmployeeWarningTooltip.vue';


    export default {
        name: 'grid-username-template',

        props: {
            showAvatar: {
                type: Boolean,
                default: false,
            },
            showName: {
                type: Boolean,
                default: false,
            },
            showLastName: {
                type: Boolean,
                default: false,
            },
            showAltUsername: {
                type: Boolean,
                default: false,
            },
            showUsername: {
                type: Boolean,
                default: true,
            },
            profilePage: {
                type: String,
                default: 'ProfileInfo',
            },

            dataLocation: {
                type: String,
                default: null,
            },
            role: {
                type: Boolean,
                default: false,
            }

            
            
        },

        data: function() {
            return {
                data: {},
                content (warnings) {
                    return () => {
                        return { 
                            template : {
                                extends: EmployeeWarningTooltip,
                                propsData: {warnings: warnings},
                            }
                            
                        }
                    }
                }
            }
        },
        
        computed: {
            warnings() {
                if (this.data?.warnings == undefined) return {}

                return this.data?.warnings;
            },

            dataComputed() {
                let data;
                if (this.dataLocation) {
                    data =  this.data[this.dataLocation]
                } else {
                    data = this.data;
                }

                if (data.user == undefined)
                    return data;
                else   
                    return data.user;
            },

            userRole() {
                if (this.data.role === "MANAGER") return 1;
                if (this.data.pwo?.pwo_role === 1) return 1;
                return 0;
            }
        },

        methods: {
            hasWarnings () {
                if (!this.data.warnings) return false;

                if (Object.keys(this.data.warnings).length > 0){
                    return true;
                }
            },
            
            hasDangerWarnings () {
                if (!this.hasWarnings()) return false;
                
                return Object.values(this.data.warnings).some(x => x < 0)
            },

            getAvatarUrl(employee) {
                return employee.avatar_url?employee.avatar_url:require('@/assets/profilna.png')
            },

        }

    }
</script>

<style>
    .template-avatar{
        height: 16px;
        width: 16px;
        object-fit: cover;
    }

    #tooltipContent {
        padding: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }
</style>
